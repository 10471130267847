import React, { ReactNode, useState } from 'react';
import {
  AppShell as MantineAppShell,
  AppShellProps,
  Button,
  Loader,
  Text,
  Modal,
  Stack,
  Alert,
  TextInput,
  Anchor,
} from '@mantine/core';
import Navbar from './Navbar';
import { useSession } from '../../hooks/useSession';
import { UserRole } from '../../graphql/types.generated';
import Link from 'next/link';
import {
  useCreateGraySessionMutation,
  useSessionQuery,
} from '../../graphql/operations/users.generated';
import { useRouter } from 'next/router';
import { isEmpty, isNil, noop } from 'lodash';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useHealthQuery } from '../../graphql/operations/test.generated';
import { COOKIE_NAME, COOKIE_OPTIONS, IS_PRODUCTION } from '../../constants';
import ms from 'ms';
import { namedOperations } from '../../graphql/namedOperations.generated';
import { useCreateEventMutation } from '../../graphql/operations/eventStore.generated';
import LogRocket from 'logrocket';
import { EventTypeEnum, segmentIdentify } from '../../utils/analytics';
import * as Sentry from '@sentry/nextjs';
import { useForm } from 'react-hook-form';

type MainAppShellProps = {
  children: ReactNode;
  withAuth?: boolean;
  adminOnly?: boolean;
  loading?: boolean;
  navbar?: AppShellProps['navbar'];
  header?: AppShellProps['header'];
  aside?: AppShellProps['aside'];
  fixed?: boolean;
};

const MainAppShell = ({
  children,
  withAuth,
  adminOnly,
  navbar,
  fixed = false,
}: MainAppShellProps) => {
  const { session, loading: loadingSession } = useSession();
  const showLoader = (withAuth || adminOnly) && (typeof window === 'undefined' || loadingSession);
  let childrenToShow = null;

  if (withAuth && !loadingSession && !session) {
    childrenToShow = <div>You are not authenticated</div>;
  }

  if (adminOnly && session?.user?.role !== UserRole.Admin) {
    childrenToShow = (
      <div>
        <Text>You are not authenticated 😿</Text>
        <Link legacyBehavior href="/login" passHref>
          <Button mt="md">Login</Button>
        </Link>
      </div>
    );
  }

  if (showLoader) {
    childrenToShow = <Loader />;
  }

  return (
    <MantineAppShell
      padding="md"
      fixed={fixed}
      header={<Navbar />}
      navbar={navbar}
      styles={(theme) => ({
        main: {
          minHeight: '100vh',
          paddingBottom: 64,
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        },
      })}
    >
      {childrenToShow || children}
    </MantineAppShell>
  );
};

export const PublicAppShell = ({
  children,
  navbar,
  header,
  aside,
  callbacks,
  source,
}: MainAppShellProps & {
  source: 'INSIGHTS' | 'DIGITAL_QUOTE' | 'INSIGHTS_STANDALONE' | 'MARKETPLACE';
  callbacks: any[];
}) => {
  const router = useRouter();

  const [showModal, setShowModal] = useState(() => isNil(getCookie(COOKIE_NAME)));
  const [createEvent] = useCreateEventMutation();

  useHealthQuery();

  useSessionQuery({
    skip: isEmpty(getCookie(COOKIE_NAME)),
    onError: (err) => {
      if (err.networkError) {
        return null;
      }
      deleteCookie(COOKIE_NAME, COOKIE_OPTIONS);
      setShowModal(true);
    },
    onCompleted: async (response) => {
      if (IS_PRODUCTION) {
        LogRocket.identify(response?.session?.user?.id, {
          name: response?.session?.user?.name,
          email: response?.session?.user?.email,
          // @ts-expect-error
          organizationId: response?.session?.organization?.id,
        });
        segmentIdentify(response?.session?.user?.id, {
          name: response?.session?.user?.name,
          email: response?.session?.user?.email,
          organizationId: response?.session?.organization?.id,
          company: {
            id: response?.session?.organization?.id,
            name: response?.session?.organization?.name,
          },
          logrocketSessionURL: LogRocket.sessionURL,
        });

        Sentry.setUser({
          email: response?.session?.user?.email,
          id: response?.session?.user?.id,
          organization: response?.session?.organization?.name,
        });

        await createEvent({
          variables: {
            eventName: EventTypeEnum.DigitalQuoteViewed,
            payload: {
              email: response.session.user?.email,
              id: response.session?.user?.id,
              quoteId: router.query.quoteId as string,
            },
          },
        });
      }
    },
  });

  const [mutate, { error: createGraySessionError }] = useCreateGraySessionMutation({
    onCompleted: async (response) => {
      if (response.createGraySession) {
        setShowModal(false);
        setCookie(COOKIE_NAME, response.createGraySession?.session?.token, {
          path: router.asPath,
          maxAge: ms('24 hours') / 1000,
          domain: window.location.hostname,
        });
        if (callbacks) {
          await Promise.allSettled(callbacks.map((callback) => callback()));
        }
      }
    },
    refetchQueries: [namedOperations.Query.Session],
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  return (
    <MantineAppShell
      padding="md"
      header={header}
      navbar={navbar}
      aside={aside}
      asideOffsetBreakpoint="sm"
      styles={(theme) => ({
        main: {
          minHeight: '100vh',
          paddingBottom: 64,
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        },
      })}
    >
      <Modal
        opened={showModal}
        withCloseButton={false}
        size="md"
        title="Enter email to continue"
        onClose={noop}
      >
        <form
          onSubmit={handleSubmit(async (values) => {
            await mutate({
              variables: {
                input: {
                  email: values.email,
                  source,
                  quoteId: router.query.quoteId as string,
                },
              },
            });
          })}
        >
          <Stack>
            {createGraySessionError && (
              <Alert title="Error" color="red">
                {(createGraySessionError as Error).message}
              </Alert>
            )}
            <TextInput
              label="Email"
              type="email"
              {...register('email', { required: 'Required' })}
              error={errors.email?.message}
            />
            <Button type="submit">Submit</Button>
            <Text size="xs">
              By clicking submit, I agree to Shepherd's{' '}
              <Anchor
                href="https://shepherd-public.s3.us-west-1.amazonaws.com/Shepherd+Labs+-+Privacy+Policy.pdf"
                target="_blank"
                style={{ textDecoration: 'underline' }}
              >
                Privacy Policy
              </Anchor>{' '}
              and{' '}
              <Anchor
                href="https://shepherd-public.s3.us-west-1.amazonaws.com/Shepherd+Labs+-+Terms+of+Use.pdf"
                target="_blank"
                style={{ textDecoration: 'underline' }}
              >
                Terms of Service
              </Anchor>
              .
            </Text>
          </Stack>
        </form>
      </Modal>
      {children}
    </MantineAppShell>
  );
};

export default MainAppShell;
